import type { IStar } from "@/views/login/type";
import type { Ref } from "vue";
import { onMounted, onUnmounted } from "vue";

export default function createStars(stars: Ref<IStar[]>) {
  const generateRandomStar = () => {
    const star: IStar = {
      id: Date.now(),
      style: {
        // 半径2~4px
        width: `${Math.random() * 4 + 2}px`,
        height: `${Math.random() * 4 + 2}px`,
        left: `${Math.random() * window.innerWidth}px`,
        top: `${Math.floor(Math.random() * window.innerHeight)}px`,
      }
    };

    stars.value.push(star);
    setTimeout(() => {
      removeStar(star.id);
    }, 10000);
  };

  const removeStar = (id) => {
    stars.value = stars.value.filter(star => star.id !== id);
  };

  onMounted(() => {
    const interval = setInterval(generateRandomStar, 600);
    onUnmounted(() => {
      clearInterval(interval);
    })
  });
}
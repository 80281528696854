<template>
  <div class="login">
    <div v-for="star in stars" :key="star.id" class="star" :style="star.style"></div>
    <div v-for="meteor in meteors" :key="meteor.id" :style="meteor.style" class="meteor"></div>
    <ClientOnly>
      <NuxtPage />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();


interface IStar {
  id: number;
  style: {
    width: string;
    height: string;
    left: string;
    top: string;
  };
}

interface IMeteor {
  id: number;
  style: {
    width: string;
    right: string;
    top: string;
  };
}

const stars: Ref<IStar[]> = ref([]);
// 生成星星
createStars(stars);

const meteors: Ref<IMeteor[]> = ref([]);
// 生成流星
createMeteor(meteors);
</script>

<style lang="less" scoped>
.login {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .star {
    position: absolute;
    z-index: 1;
    width: 10px;
    height: 10px;
    margin: 100px auto;
    border-radius: 50%;
    background: radial-gradient(at 70% 30%,
        rgb(246, 243, 243) 1px,
        #fbfdff -4%,
        #eeeef4 38%);
    /* 蓝色光影 */
    box-shadow: 0 0 6px 1px rgb(37, 179, 249);
    animation: myStar 5s linear forwards;
  }

  /* 繁星点点动画 */
  @keyframes myStar {
    0% {
      opacity: 0.5;
    }

    25% {
      transform: translateX(-50%);
    }

    50% {
      opacity: 0.8;
      transform: translateY(50%);
    }

    75% {
      transform: translateX(30%);
    }

    90% {
      opacity: 0.9;
    }

    100% {
      opacity: 0;
      transform: translateY(-20%);
    }
  }

  .meteor {
    position: absolute;
    z-index: 2;
    /* 渐变 */
    background: linear-gradient(to right, rgb(255, 255, 255), rgba(0, 0, 0, 0));
    height: 2px;
    width: 100px;
    /* 蓝色光影 */
    box-shadow: 0 0 -10px rgb(37, 179, 249);
    border-radius: 30px;
    right: 0;
    top: 0;
    animation: myMeteor 1s linear infinite;
  }

  /* 流星划过动画 */

  @keyframes myMeteor {
    0% {
      transform: translate(-320%, 12000%) rotate(-30deg);
    }

    25% {
      transform: translate(-535%, 17000%) rotate(-30deg);
    }

    100% {
      transform: translate(-1000%, 24500%) rotate(-30deg);
    }
  }
}
</style>

import type { IMeteor } from "@/views/login/type";
import type { Ref } from "vue";
import { onMounted, onUnmounted } from "vue";

export default function createMeteor(meteors: Ref<IMeteor[]>) {
  const generateRandomMeteor = () => {
    const size = Math.random() * 10 + 100;
    const meteor: IMeteor = {
      id: Date.now(),
      style: {
        width: `${size}px`,
        right: `${Math.random() * window.innerWidth}px`,
        top: `${Math.random() * window.innerHeight}px`,
      }
    }
    meteors.value.push(meteor);
    setTimeout(() => {
      removeMeteor(meteor.id);
    }, 3000);
  }
  const removeMeteor = (id) => {
    meteors.value = meteors.value.filter(meteor => meteor.id !== id);
  };

  onMounted(() => {
    const interval = setInterval(generateRandomMeteor, 900);
    onUnmounted(() => {
      clearInterval(interval);
    })
  });


}